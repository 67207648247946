import React from "react"
import styled from "styled-components"
import { cover } from "./Style"

const StyledFrame = styled.video`
  ${props => (props.cover ? cover() : null)}
`

export default function Video({
  muted = true,
  autoPlay = true,
  loop = true,
  width,
  height,
  poster,
  src,
  controls,
  cover = true,
  ...props
}) {
  const videoAttributes = () => {
    return {
      muted: muted ? "muted" : null,
      autoPlay: autoPlay ? true : null,
      width: width || "auto",
      height: height || "auto",
      poster: poster || null,
      controls: controls || null,
      loop: loop || null,
    }
  }
  return (
    <StyledFrame as="video" cover={cover} {...videoAttributes()} {...props}>
      <source src={src} type="video/mp4" />
    </StyledFrame>
  )
}

export function Youtube({ src, title, cover = true }) {
  return (
    <StyledFrame
      as="iframe"
      cover={cover}
      src={src}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}
