import styled from "styled-components"
import { sidePadding, colors, breakpoints } from "../components/Style"

export const Block = styled.div`
  padding: 3.2rem ${sidePadding}rem;
  margin-bottom: 5rem;
  background-color: ${colors.gray["700"]};
  box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.05);

  @media ${breakpoints.medium} {
    padding: 4.1rem ${sidePadding * 5}rem;
    margin-right: ${sidePadding}rem;
    margin-left: ${sidePadding}rem;
  }

  @media ${breakpoints.large} {
    max-width: 68.8rem;
    margin-left: auto;
    margin-right: auto;
  }
`
